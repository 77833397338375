import React from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import LandingGeneral from "./pages/landingGeneral/landingGeneral";
import SignupDialog from "./pages/auth/Signup";


function App() {
    const location = useLocation();
    const navigate = useNavigate();
    if (location.state?.redirectTo)
        return (
            <Navigate
                to={location.state.redirectTo}
                replace
                state={{ backgroundLocation: location }}
            />
        );
  return (
    <>
        {location.state?.backgroundLocation &&
        <Routes>
            <Route path="/signup" element={<SignupDialog />} />

        </Routes>
        }
        <Routes location={location.state?.backgroundLocation || location}>
            <Route path="/" element={<LandingGeneral/>}/>
            <Route path="/signup" element={<Navigate to="/" replace state={{ redirectTo: "/signup" }} />} />
        </Routes>

    </>
  );
}

export default App;
