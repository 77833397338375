import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import Section from "../../kit/section";

export default function QuizInteractiveGeneral() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000));
    const isSmallMonitor = useMediaQuery(theme.breakpoints.down(1200));
    return(
        <Section
            tag={"section"}
            bg={"#434657"}
            sxsect={{
                // minHeight: "7049px",
                marginTop: isMobile ? "-65px" : "-70px",
                alignItems: "flex-start",
                zIndex: 2,
                position: "relative",
                borderRadius: isMobile ? "0 0 30px 30px" : "0 0 80px 80px"
            }}
            mwidth={"1160px"}
            sxcont={{
                padding: isMobile
                    ? "80px 16px 44px 16px"
                    : isTablet
                        ? "125px 40px 0 40px"
                        : "130px 0px 0 0px",
                position: "relative",
                boxSizing: "border-box",
            }}
        >
            <Box
                color="#ffffff"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    marginBottom: isMobile ? "10px" : "65px",
                    gap: isMobile ? "55px" : "60px",
                }}
            >
                <Box>
                    <Typography variant="h6" fontSize="36px" maxWidth={"500px"} lineHeight={"normal"}>
                        Как будут выглядеть квизы на вашем сайте
                    </Typography>
                    <Typography fontSize="18px" marginTop={"20px"}>Интерактивные</Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: isMobile ? "70px" : "140px"
                    }}
                >
                    <QuizBox>
                        <Typography>interactive</Typography>
                    </QuizBox>
                    <QuizBox>
                        <Typography>interactive</Typography>
                    </QuizBox>
                    <QuizBox>
                        <Typography>interactive</Typography>
                    </QuizBox>
                    <QuizBox>
                        <Typography>interactive</Typography>
                    </QuizBox>
                    <QuizBox marginBottom={isMobile ? "-175px" : "-185px"}>
                        <Typography>interactive</Typography>
                    </QuizBox>
                </Box>
            </Box>
        </Section>
    )
}

interface Props {
    children: React.ReactNode,
    marginBottom?: string
}

function QuizBox (props: Props) {
    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.down(1000));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    return(
        <Box
            sx={{
                width: "100%",
                maxWidth: "1160px",
                height: isMobile ? "634px" : isTablet ? "491px" : "608px",
                borderRadius: "12px",
                backgroundColor: "#252734",
                position: "relative",
                marginBottom: props.marginBottom,
                boxShadow: "0px 2.767256498336792px 8.550822257995605px 0px #D2D0E111"

            }}
        > {props.children}</Box>
    )
}