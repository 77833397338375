import { Typography, useMediaQuery, Box, useTheme, Button } from "@mui/material";
import {Logo} from "../images/logo";
import Section from "./section";



export default function Footer() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Section
      tag="footer"
      bg={"#252734"}
      mwidth={"1160px"}
      sxsect={{
        height: isMobile ? "550px" : "236px",
        padding: isMobile ? "0 16px" : isTablet ? "0 40px" : "0 20px",
        position: "relative",
        alignItems: "start",
        zIndex: 10,
        borderRadius: isMobile ? "30px 30px 0 0" : "80px 80px 0 0"
      }}
      sxcont={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "normal",
        alignItems: isMobile ? "normal" : "flex-start",
        gap: isMobile ? "40px" : "136px",
        padding: 0,
        marginTop: "60px"
      }}
    >
      <Box sx={{ ml: isMobile ? "0" : isTablet ? "40px" : "0" }}>
        <Box
          sx={{
            width: "124px",
            height: "48px",
            backgroundSize: "contain",
          }}
        >
          <Logo width={124} color={"white"}/>
        </Box>
        <Typography
            sx={{
                color: "rgba(114, 112, 116, 1)",
                fontSize: "14px",
                marginTop: "10px"
            }}>
            (c) 2023 Examplelink.com
        </Typography>
      </Box>

      <Box
          sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              flexWrap: "wrap",
              alignItems: isMobile ? undefined : "start",
              // mt: isMobile ? undefined : "45px",
              gap: isMobile ? "18px" : "145px",
          }}
      >
          <Box
              sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "18px",
                  alignItems: isMobile ? "baseline" : undefined
              }}
          >
              <Button
                  variant="pena-navitem-dark"
                  href={"https://docs.pena.digital/docs"}
              >
                  Пользовательское соглашение
              </Button>
          </Box>
      </Box>
    </Section>
  );
}
