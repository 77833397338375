import { FC, SVGProps } from "react";

export const Burger: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_35)">
            <path d="M21.7778 18C22.0893 18.0003 22.3889 18.0979 22.6154 18.2728C22.842 18.4478 22.9783 18.687 22.9965 18.9414C23.0148 19.1958 22.9136 19.4464 22.7137 19.6418C22.5137 19.8373 22.2301 19.9629 21.9208 19.993L21.7778 20H2.22222C1.9107 19.9997 1.61107 19.9021 1.38455 19.7272C1.15803 19.5522 1.02171 19.313 1.00346 19.0586C0.9852 18.8042 1.08638 18.5536 1.28632 18.3582C1.48627 18.1627 1.76988 18.0371 2.07922 18.007L2.22222 18H21.7778ZM21.7778 11C22.1019 11 22.4128 11.1054 22.642 11.2929C22.8712 11.4804 23 11.7348 23 12C23 12.2652 22.8712 12.5196 22.642 12.7071C22.4128 12.8946 22.1019 13 21.7778 13H2.22222C1.89807 13 1.58719 12.8946 1.35798 12.7071C1.12877 12.5196 1 12.2652 1 12C1 11.7348 1.12877 11.4804 1.35798 11.2929C1.58719 11.1054 1.89807 11 2.22222 11H21.7778ZM21.7778 4C22.1019 4 22.4128 4.10536 22.642 4.29289C22.8712 4.48043 23 4.73478 23 5C23 5.26522 22.8712 5.51957 22.642 5.70711C22.4128 5.89464 22.1019 6 21.7778 6H2.22222C1.89807 6 1.58719 5.89464 1.35798 5.70711C1.12877 5.51957 1 5.26522 1 5C1 4.73478 1.12877 4.48043 1.35798 4.29289C1.58719 4.10536 1.89807 4 2.22222 4H21.7778Z" fill="#000000"/>
        </g>
        <defs>
            <clipPath id="clip0_1_35">
                <rect width="24" height="24" fill="#000000"/>
            </clipPath>
        </defs>
    </svg>

);
