import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import Bubles from "../../images/bubble2.png"
import Section from "../../kit/section";
import plansHero from "../../images/heroImage.svg";

export default function QuizInteractiveLight() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000));
    const isSmallMonitor = useMediaQuery(theme.breakpoints.down(1200));
    return(
        <Section
            tag={"section"}
            bg={"#F2F3F7"}
            sxsect={{
                marginTop: 0,
                alignItems: "flex-start",
                zIndex: 1,
                position: "relative",
            }}
            mwidth={"1160px"}
            sxcont={{
                padding: isMobile
                    ? "190px 16px 34px 16px"
                    : isTablet
                        ? "260px 40px 0px 40px"
                        : "260px 0px 44px 0px",
                position: "relative",
                boxSizing: "border-box",
            }}
        >
            <Box
                color="#ffffff"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    marginBottom: isMobile ? "10px" : "65px",
                    gap: isMobile ? "30px" : undefined,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: isMobile ? "70px" : "140px"
                    }}
                >
                    <QuizBox>
                        <Typography>interactive</Typography>
                    </QuizBox>
                    <QuizBox>
                        <Typography>interactive</Typography>
                    </QuizBox>
                    <QuizBox>
                        <Typography>interactive</Typography>
                    </QuizBox>
                    <QuizBox>
                        <Typography>interactive</Typography>
                    </QuizBox>
                </Box>
            </Box>
            <Box
                component={"img"}
                src={Bubles}
                sx={{
                    position: "absolute",
                    bottom: isMobile
                        ? "-157px"
                        : isTablet
                            ? "-364px"
                            : isSmallMonitor
                                ? "-146px"
                                : "-476px",

                    width: isMobile ? "179%" : isTablet
                        ? "158%" : "175%",
                    left: isMobile
                        ? "-83px"
                        : isTablet
                            ? "-182px"
                            : isSmallMonitor
                                ? "460px"
                                : "-319px",
                    zIndex: 0
                }}
            />
        </Section>
    )
}


interface Props {
    children: React.ReactNode,
    marginBottom?: string
}

function QuizBox (props: Props) {
    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.down(1000));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    return(
        <Box
            sx={{
                width: "100%",
                maxWidth: "1160px",
                height: isMobile ? "634px" : isTablet ? "491px" : "608px",
                borderRadius: "12px",
                backgroundColor: "#FFFFFF",
                position: "relative",
                marginBottom: props.marginBottom,
                boxShadow: "0px 2.767256498336792px 8.550822257995605px 0px #D2D0E111",
                zIndex: 1
            }}
        > {props.children}</Box>
    )
}